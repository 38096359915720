export const postPhoneFunc = (phone: any) => {
  const eight = "8";
  if (phone.substring(0, 2).includes(7) && !phone.match(/[a-zA-Z]+/gm)) {
    return eight + phone.replace(/[^+\d]/g, "").slice(2);
  } else {
    return phone;
  }
};

export const toCapitalize = (str: string) => {
  if (!str) return str;
  return str[0].toUpperCase() + str.slice(1);
};
