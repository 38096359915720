import AdminService from '../services';
import { adminSlice } from "./adminSlice";
import { message } from "antd";
import { errorsKey } from "../utils";
import {
  IAddOauthApp,
  IAddOauthAppResponse,
  IAddRole,
  IBlock,
  IBlockList,
  ICodeBlock,
  ICreateFakeUsers,
  IDeactivateUser,
  IDeleteOauthApp,
  IGetAllUsers,
  IGetLogs,
  IGetUserData,
  IGetUserToken,
  IPagination,
  ISetUsers,
  ISuperAdmin,
  IUpdateNorm,
  IUpdateOauthApp,
  IUploadImg,
  IWordsResponse
} from '../types/types';
import { createAsyncThunk } from "@reduxjs/toolkit";
import ProfileService from '../services';

export const validateSnils = createAsyncThunk(
  'user/validateSnils',
  async (value: string, {dispatch}) => {
    let snils = value.replace(/[^+\d]/g, '')
    if (typeof snils === 'number') {
      snils = String(snils);
    } else if (typeof snils !== 'string') {
      snils = '';
    }

    if (!snils.length) {
      dispatch(adminSlice.actions.fetchError('СНИЛС пуст'));
    } else if (/[^0-9]/.test(snils)) {
      dispatch(adminSlice.actions.fetchError('СНИЛС может состоять только из цифр'));
    } else if (snils.length !== 11) {
      dispatch(adminSlice.actions.fetchError('СНИЛС может состоять только из 11 цифр'));
    } else if (snils === '00000000000') {
      dispatch(adminSlice.actions.fetchError('СНИЛС не может состоять из одних нулей'))
    } else {

      let sum = 0;
      let checkDigit = 0;

      for (let i = 0; i < 9; i++) {
        sum += parseInt(snils[i]) * (9 - i);
      }

      if (sum < 100) {
        checkDigit = sum;
      } else if (sum > 101) {
        checkDigit = Math.floor(sum % 101);
        if (checkDigit === 100) {
          checkDigit = 0;
        }
      }

      if (checkDigit === parseInt(snils.slice(-2))) {
        dispatch(adminSlice.actions.fetchError(''));
      } else {
        dispatch(adminSlice.actions.fetchError('Неправильное контрольное число'));
      }
    }
  }
)

// Рендер текущей страницы
const stayOnThisPage = (data: ISuperAdmin, pagination: IPagination, query: string, dispatch: any) => {
  const dataValue = {
    superAdmin: data,
    pagination: pagination,
    query: query,
  }
  dispatch(getAllUsers_Pagination(dataValue))
}

// @-Changed Отправка DaData
export const getUserGeoData = createAsyncThunk(
  'user/getUserGeoData',
  async ({ token, query }: IGetUserData, { dispatch }) => {
    const value = JSON.stringify({ token, query })
    const response = await AdminService.$getUserGeoData(value)
    if (response.data.status) {
      let res = response.data.value;
      dispatch(adminSlice.actions.UserAddress(res))
    } else {
      const err = response.data.errors[0].key
      const show = errorsKey(err)
      message.error(show)
    }
  }
)

// Отправка DaDataInn
export const getUserInnData = createAsyncThunk(
  'user/getUserInnData',
  async ({ token, query }: IGetUserData, { dispatch }) => {
    const value = JSON.stringify({ token, query })
    const response = await AdminService.$getUserInnData(value)
    if (response.data.status) {
      let res = response.data.value;
      dispatch(adminSlice.actions.UserInn(res))
    } else {
      const err = response.data.errors[0].key
      const show = errorsKey(err)
      message.error(show)
    }
  }
)

// получнеие логов
export const getLogs = createAsyncThunk(
  'user/getLogs',
  async ({ superAdmin, pagination, query, query_service, query_date, query_type }: IGetLogs, { dispatch }) => {
    dispatch(adminSlice.actions.usersFetchingLoading(true));
    const paginate = {
      login: superAdmin.login,
      password: superAdmin.password,
      pages: {
        limit: pagination.limit,
        page: pagination.page,
        offset: 0
      },
      query_data: String(query),
      query_service: query_service,
      query_date: query_date,
      query_type: query_type
    }

    const value = JSON.stringify(paginate)
    dispatch(adminSlice.actions.fetchLogs([]))
    const response = await AdminService.$getLogs(value)
    if (response.data.status) {
      let res = response.data.values
      dispatch(adminSlice.actions.fetchLogs(res))
    } else {
      const err = response.data.errors[0].key
      const show = errorsKey(err)
      message.error(show)
    }
    dispatch(adminSlice.actions.usersFetchingLoading(false));
  }
)

//загрузка изображений
export const upload_file = createAsyncThunk(
  'user/upload_file',
  async ({ token, file }: IUploadImg, { dispatch }) => {
    const blob = await fetch(file).then(f => f.blob());
    const data = new FormData();
    data.append('token', String(token))
    data.append('file', blob)
    const response = await AdminService.$upload_File(data)
    if (response.data.status) {
      dispatch(adminSlice.actions.UserPhoto(response.data.values[0]))
      message.success('вы успешно добавили фото профиля')
    } else {
      const err = response.data.errors[0].key
      const show = errorsKey(err)
      message.error(show)
    }
  }
)

// Добавление роли
export const addRole = createAsyncThunk(
  'user/addRole',
  async ({ superAdmin, id, role, service }: IAddRole, { dispatch }) => {
    const data = {
      login: superAdmin.login,
      password: superAdmin.password,
      user_id: id,
      role: { service: service[0], role }
    };
    const value = JSON.stringify(data);
    const response = await AdminService.$addRole(value)
    if (response.data.status) {
      dispatch(adminSlice.actions.UserPhoto(response.data.values[0]))
      message.success('Вы успешно добавили роль')
    } else {
      const err = response.data.errors[0].key
      const show = errorsKey(err)
      message.error(show)
    }
  }
)

export const deleteRole = createAsyncThunk(
  'user/deleteRole',
  async ({ superAdmin, id, role, service }: IAddRole, thunkAPI) => {
    try {
      const data = {
        login: superAdmin.login,
        password: superAdmin.password,
        user_id: id,
        role: { service: service[0], role }
      };
      const value = JSON.stringify(data);

      const response = await AdminService.$deleteRole(value);

      if (response.data.status) {
        message.success('Вы успешно удалили роль');
      } else {
        const err = response.data.errors[0].key;
        const show = errorsKey(err);
        message.error(show);
        return thunkAPI.rejectWithValue(show);
      }
    } catch (error) {
      message.error('Произошла ошибка');
      return thunkAPI.rejectWithValue('Произошла ошибка');
    }
  }
);

export const getAllUsers_Pagination = createAsyncThunk(
  'user/getAllUsers_Pagination',
  async ({ superAdmin, pagination, query }: IGetAllUsers, { dispatch }) => {
    dispatch(adminSlice.actions.usersFetchingLoading(true));
    const paginate = {
      login: superAdmin.login,
      password: superAdmin.password,
      pages: {
        limit: pagination && pagination.limit,
        page: pagination && pagination.page
      },
      query: query
    };
    const value = JSON.stringify(paginate);
    const response = await AdminService.$getAllUsers(value);
    if (response.data.status) {
      localStorage.setItem('superAdmin', JSON.stringify(superAdmin));
      let user = response.data.values[0];
      if (!user) {
        user = { users: [], count: 1 }
      }
      dispatch(adminSlice.actions.usersFetchingSuccess(user))
    } else {
      const err = response.data.errors[0].message;
      dispatch(adminSlice.actions.usersFetchingError(err));
      message.error(err);
    }
  }
)

export const deactivate_user = createAsyncThunk(
  'user/deactivate_user',
  async ({ superAdmin, user_id, pagination, query }: IDeactivateUser, { dispatch }) => {
    dispatch(adminSlice.actions.usersFetchingLoading(true));
    const data = {
      user_id,
      login: superAdmin.login,
      password: superAdmin.password,
    };
    const value = JSON.stringify(data);
    const response = await AdminService.$deactivateUser(value);

    if (response.data.status) {
      stayOnThisPage(data, pagination, query, dispatch)
    } else {
      const err = response.data.errors[0].message;
      message.error(err);
    }

    dispatch(adminSlice.actions.usersFetchingLoading(false));
  }
)

export const delete_user = createAsyncThunk(
  'user/delete_user',
  async ({ superAdmin, user_id, pagination, query }: IDeactivateUser, { dispatch }) => {
    dispatch(adminSlice.actions.usersFetchingLoading(true));
    const data = {
      user_id,
      login: superAdmin.login,
      password: superAdmin.password,
    };
    const value = JSON.stringify(data);
    const response = await AdminService.$deleteUser(value);

    if (response.data.status) {
      stayOnThisPage(data, pagination, query, dispatch)
    } else {
      const err = response.data.errors[0].message;
      message.error(err);
    }

    dispatch(adminSlice.actions.usersFetchingLoading(false));
  }
)

export const restore_user = createAsyncThunk(
  'user/restore_user',
  async ({ superAdmin, user_id, pagination, query }: IDeactivateUser, { dispatch }) => {
    dispatch(adminSlice.actions.usersFetchingLoading(true));
    const data = {
      user_id,
      login: superAdmin.login,
      password: superAdmin.password,
    };
    const value = JSON.stringify(data);
    const response = await AdminService.$restoreUser(value);

    if (response.data.status) {
      stayOnThisPage(data, pagination, query, dispatch)
    } else {
      const err = response.data.errors[0].message;
      message.error(err);
    }

    dispatch(adminSlice.actions.usersFetchingLoading(false));
  }
)

export const get_user_token = createAsyncThunk(
  'user/get_user_token',
  async ({ superAdmin, user_id, service }: IGetUserToken, { dispatch }) => {
    const data = {
      login: superAdmin.login,
      password: superAdmin.password,
      user_id,
      service
    };
    const value = JSON.stringify(data);
    const response = await AdminService.$getUserToken(value);

    if (response.data.status) {
      const userToken = response.data.values[0];
      dispatch(getUserData(userToken));
      dispatch(adminSlice.actions.fetchUserToken(userToken));
    } else {
      const err = response.data.errors[0].message;
      message.error(err);
    }
  }
)

export const getUserData = createAsyncThunk(
  'user/getUserData',
  async (token: string, { dispatch }) => {
    const response = await AdminService.$getUserData(JSON.stringify({ token }));
    if (response.data.status) {
      const res = response.data.values[0];
      dispatch(adminSlice.actions.usersFetchForEditSuccess(res));
    } else {
      const err = response.data.errors[0].message;
      message.error(err);
    }
  }
)

export const setUsers = createAsyncThunk(
  'user/set_users',
  async ({ data, setIsEditModule }: ISetUsers, { dispatch }) => {
    const response = await AdminService.$set_User(data)
    dispatch(adminSlice.actions.usersFetchingLoading(true));
    if (response.data.status) {
      message.success('Вы успешно изменили данные');
      setIsEditModule(false);
    } else {
      const err = response.data.errors[0].message
      message.error(err)
    }
    dispatch(adminSlice.actions.usersFetchingLoading(false));
  }
)

// Активация пользователя
export const activate_user = createAsyncThunk(
  'user/activate_user',
  async ({ superAdmin, user_id, pagination, query }: IDeactivateUser, { dispatch }) => {
    dispatch(adminSlice.actions.usersFetchingLoading(true));
    const data = {
      user_id,
      login: superAdmin.login,
      password: superAdmin.password,
    };
    const value = JSON.stringify(data);
    const response = await AdminService.$activateUser(value);

    if (response.data.status) {
      stayOnThisPage(data, pagination, query, dispatch)
    } else {
      const err = response.data.errors[0].message;
      message.error(err);
    }

    dispatch(adminSlice.actions.usersFetchingLoading(false));
  }
)

//обновление пулл запроса
export const update_pull = createAsyncThunk(
  'user/update_pull',
  async (key: string) => {
    const data = {
      key: key,
    }
    const value = JSON.stringify(data)
    await AdminService.$updatePull(value)
  }
)

export const get_list_words = createAsyncThunk(
  'user/get_list_words',
  async (userData: string, {dispatch}) => {
    const response = await AdminService.$get_list_words(userData)
    if (response.data.status) {
      const res = response.data.values[0].value;
      dispatch(adminSlice.actions.fetchCodeWords(res));
    } else {
      const err = response.data.errors[0].message;
      message.error(err);
    }
  }
)

export const set_list_words = createAsyncThunk(
  'user/get_list_words',
  async ({userData, list}: IWordsResponse, {dispatch}) => {
    const values = {...userData, list}
    const data = JSON.stringify(values)
    const response = await AdminService.$set_list_words(data)
    if (response.data.status) {
      message.success('Вы сохранили слова')
      dispatch(adminSlice.actions.updateCodeWord(list))
    } else {
      const err = response.data.errors[0].message;
      message.error(err);
    }
  }
)

export const getBlock = createAsyncThunk(
  'user/getBlock',
  async (data: IBlockList, {dispatch}) => {
    const response = await ProfileService.$getBlockList(data);
    if (response.data.values) {
      dispatch(adminSlice.actions.updateNorm(response.data.values[0].number_of_attempts))
      return response.data.values
    } else {
      const err = response.data.errors[0].message;
      message.error(err);
      return undefined;
    }
  }
)

export const getCodeBlock = createAsyncThunk(
  'user/getCodeBlock',
  async (data: ISuperAdmin) => {
    const response = await ProfileService.$getCodeBlockList(data);
    if (response.data.values) {
      return response.data.values
    } else {
      const err = response.data.errors[0].message;
      message.error(err);
      return undefined;
    }
  }
)

export const block = createAsyncThunk(
  'user/block',
  async (data: IBlock) => {
    const response = await ProfileService.$block(data);
    if (response.data.values) {
      return response.data.values
    } else {
      const err = response.data.errors[0].message;
      message.error(err);
      return undefined;
    }
  }
)

export const unblock = createAsyncThunk(
  'user/unblock',
  async (data: any) => {
    const response = await ProfileService.$unblock(data);
    if (response.data.values) {
      return response.data.values
    } else {
      const err = response.data.errors[0].message;
      message.error(err);
      return undefined;
    }
  }
)

export const unblockCode = createAsyncThunk(
  'user/unblockCode',
  async (data: ICodeBlock) => {
    const response = await ProfileService.$unblockCode(data);
    if (response.data.values) {
      return response.data.values
    } else {
      const err = response.data.errors[0].message;
      message.error(err);
      return undefined;
    }
  }
)

export const updateNorm = createAsyncThunk(
  'user/updateNorm',
  async (norm: IUpdateNorm, {dispatch}) => {
    const response = await ProfileService.$updateNorm(norm);
    if (response.data.values) {
      dispatch(adminSlice.actions.updateNorm(norm.number))
      return response.data.values
    } else {
      const err = response.data.errors[0].message;
      message.error(err);
      return undefined;
    }
  }
)

export const codeBlock = createAsyncThunk(
  'user/codeBlock',
  async (code: ICodeBlock) => {
    const response = await ProfileService.$codeBlock(code);
    if (response.data.values) {
      return response.data.values
    } else {
      const err = response.data.errors[0].message;
      message.error(err);
      return undefined;
    }
  }
)

export const getHeader = createAsyncThunk(
  'user/getHeader',
  async () => {
    const response = await ProfileService.$getHeader();
    if (response.data.values) {
      return response.data.values
    } else {
      const err = response.data.errors[0].message;
      message.error(err);
      return undefined;
    }
  }
)

export const addHeader = createAsyncThunk(
  'user/addHeader',
  async (item: any) => {
    const response = await ProfileService.$addHeader(item);
    if (response.data.status) {
      message.success('Вы добавили новый раздел!');
    } else {
      const err = response.data.errors[0].message;
      message.error(err);
    }
  }
)

export const updateHeader = createAsyncThunk(
  'user/updateHeader',
  async (item: any) => {
    const response = await ProfileService.$updateHeader(item);
    if (response.data.status) {
      message.success('Вы обновили раздел!');
    } else {
      const err = response.data.errors[0].message;
      message.error(err);
    }
  }
)

export const deleteHeader = createAsyncThunk(
  'user/deleteHeader',
  async (item: any) => {
    const response = await ProfileService.$deleteHeader(item);
    if (response.data.status) {
      message.success('Вы удалили раздел!');
    } else {
      const err = response.data.errors[0].message;
      message.error(err);
    }
  }
)

// Получение списка приложений Oauth
export const getOauthApps = createAsyncThunk(
  'user/getOauthApps',
  async (superAdmin: ISuperAdmin, { dispatch }) => {
    dispatch(adminSlice.actions.usersFetchingLoading(true));
    const response = await ProfileService.$getOauthApps(superAdmin);
    if (response.data.status) {
      const oauthList: IAddOauthAppResponse[] = response.data.values
      dispatch(adminSlice.actions.getOauthApps(oauthList))
    } else {
      const err = response.data.errors[0].message;
      message.error(err);
    }
    dispatch(adminSlice.actions.usersFetchingLoading(false));
  }
)

// Добавление приложения Oauth
export const addOauthApp = createAsyncThunk(
  'user/addOauthApp',
  async ({ superAdmin, values }: IAddOauthApp, { dispatch }) => {
    const data = {
      login: superAdmin.login,
      password: superAdmin.password,
      name: values.name,
      domain: values.domain,
      requested_fields: values.requested_fields,
    }
    const response = await ProfileService.$addOauthApp(data)
    if (response.data.status) {
      dispatch(adminSlice.actions.setShouldRefetchOauthApps(true))
    } else {
      const err = response.data.errors[0].message;
      message.error(err);
    }
  }
)

// Удаление приложения Oauth
export const deleteOauthApp = createAsyncThunk(
  'user/deleteOauthApp',
  async ({ superAdmin, id }: IDeleteOauthApp, { dispatch }) => {
    const data = {
      login: superAdmin.login,
      password: superAdmin.password,
      id,
    }
    const response = await ProfileService.$deleteOauthApp(data)
    if (response.data.status) {
      dispatch(adminSlice.actions.setShouldRefetchOauthApps(true))
    } else {
      const err = response.data.errors[0].message;
      message.error(err);
    }
  }
)

// Обновление приложения Oauth
export const updateOauthApp = createAsyncThunk(
  'user/updateOauthApp',
  async ({ superAdmin, values }: IUpdateOauthApp, { dispatch }) => {
    const data = {
      id: values.id,
      login: superAdmin.login,
      password: superAdmin.password,
      name: values.name,
      domain: values.domain,
      requested_fields: values.requested_fields,
    }
    const response = await ProfileService.$updateOauthApp(data)
    if (response.data.status) {
      message.success('Вы успешно изменили данные')
      dispatch(adminSlice.actions.setShouldRefetchOauthApps(true))
    } else {
      const err = response.data.errors[0].message;
      message.error(err);
    }
  }
)

// Удаление персонального пользователя
export const deletePersonalUser = createAsyncThunk(
  'user/deletePersonalUser',
  async ({ superAdmin, user_id, pagination, query }: IDeactivateUser, { dispatch }) => {
    dispatch(adminSlice.actions.usersFetchingLoading(true));
    const data = {
      user_id,
      login: superAdmin.login,
      password: superAdmin.password,
    };
    const value = JSON.stringify(data);
    const response = await AdminService.$deletePersonalUser(value);

    if (response.data.status) {
      stayOnThisPage(data, pagination, query, dispatch)
    } else {
      const err = response.data.errors[0].message;
      message.error(err);
    }

    dispatch(adminSlice.actions.usersFetchingLoading(false));
  }
)

export const createFakeUsers =createAsyncThunk(
  'user/createFakeUsers',
  async (data: ICreateFakeUsers, { dispatch }) => {
    const response = await ProfileService.$createFakeUsers(data)
    if (response.data.status) {
      message.success('Вы успешно добавили пользователей')
      dispatch(adminSlice.actions.fetchCreatedUsers(response.data.values))
    } else {
      const err = response.data.errors[0].message;
      message.error(err);
    }
  }
)
