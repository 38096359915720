  import { Button, Input, List } from 'antd';
  import styles from './BanModal.module.css';
  import Icon from '@ant-design/icons';
  import { SearchIcon } from '../../../assets/icons/SearchIcon';
  import { useEffect, useState } from 'react';
  import { useAppDispatch } from '../../../hooks/useAppDispatch';
  import { block, getAllUsers_Pagination } from '../../../store/adminAction';
  import { IBanModal, IDataForAdmin } from '../../../types/types';
  import { useTypedSelector } from '../../../hooks/useTypedSelector';
  import { postPhoneFunc, toCapitalize } from '../../../utils/phoneInputFunctions';
import { service_actions } from '../../../utils/const';

  export const BanModal: React.FC<IBanModal> = ({ closeModal }: IBanModal) => {
    const { users, count }: IDataForAdmin = useTypedSelector(
      (state) => state.adminReducer
    );
    const dispatch = useAppDispatch();
    const [selected, setSelect] = useState<string>('');
    const [term, setTerm] = useState<number | null>();
    const [pageCount, setPageCount] = useState<number>(1);
    const [inputField, setInputField] = useState<string>('');
    const [reason, setReason] = useState<string>('');

    useEffect(() => {
      const data = {
        superAdmin,
        pagination: { limit: Number(5), page: 1 },
        query: '',
      };
      dispatch(getAllUsers_Pagination(data));
    }, [])

    const superAdmin = JSON.parse(localStorage.getItem("superAdmin") || "{}");

    const addToBlockList = () => {
      dispatch(block({
        phone: selected,
        msg: reason,
        number: term,
        ...superAdmin
      }))
      closeModal();
    }

    const arrowRight = () => {
      if (pageCount > Math.ceil(count / 5) - 1) {
        return;
      }
      setPageCount(pageCount + 1)
      const data = {
        superAdmin,
        pagination: { limit: Number(5), page: pageCount + 1 },
        query: postPhoneFunc(toCapitalize(inputField)),
      };
      dispatch(getAllUsers_Pagination(data));
    };

    const arrowLeft = () => {
      if (pageCount <= 1) {
        return;
      }
      setPageCount(pageCount - 1)
      const data = {
        superAdmin,
        pagination: { limit: Number(5), page: pageCount - 1 },
        query: postPhoneFunc(toCapitalize(inputField)),
      };
      dispatch(getAllUsers_Pagination(data));
    };

    const searchUsers = (e: React.ChangeEvent<HTMLInputElement>) => {
      setPageCount(1);
      const data = {
        superAdmin,
        pagination: { limit: Number(5), page: 1 },
        query: postPhoneFunc(toCapitalize(e.target.value)),
      };
      dispatch(getAllUsers_Pagination(data));
    };

    return (
      <>
        <div className={styles.ban_container}>
          <div className={styles.ban_search}>
            <Input
              className={styles._input}
              placeholder={" Начните вводить текст для поиска"}
              prefix={<Icon component={SearchIcon} className={styles.prefix} />}
              onChange={(e) => setInputField(e.target.value)}
              onPressEnter={searchUsers}
            />
            <List
              size="small"
              className={styles.ban_list}
              bordered
              dataSource={users}
              renderItem={(user, idx) => (
                <List.Item
                  className={String(users[idx].phone) === selected ? styles.selected : ''}
                  onClick={() => setSelect(String(users[idx].phone))}>
                  <td>{user.user_info.passport.name}</td>
                  <td>{user.phone}</td>
                </List.Item>
              )}
            />
            <div className={styles.pagination_inline}>
              <div className={styles.count_all}>Всего: {count}</div>
              <div className={styles.arrow}>
                <p>
                  <i className={styles.arrow_left} onClick={arrowLeft} />
                </p>
                <div className={styles.paginationCounter}>
                  {pageCount}
                </div>
                <p>
                  <i className={styles.arrow_right} onClick={arrowRight} />
                </p>
              </div>
            </div>
          </div>
          <div>
            <Input
              className={styles.submit_button}
              placeholder={" Номер телефона"}
              value={selected}
              onChange={(e) => setSelect(e.target.value)}
            />
            <h3 className={styles.hours_title}>Срок блокировки (ч)</h3>
            <div className={styles.ban_form}>
              <Input size='large' className={styles.hours_select} onChange={(e) => setTerm(Number(e.target.value))}/>
            </div>
            <h3 className={styles.hours_title}>Причина блокировки</h3>
            <div className={styles.ban_form}>
              <Input size='large' className={styles.hours_select} onChange={(e) => setReason(e.target.value)}/>
            </div>
            <Button type="primary" className={styles.submit_button} onClick={addToBlockList}>{service_actions.ban}</Button>
          </div>
        </div>
      </>
    )
  }
