import { Button, Popconfirm, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import styles from './BanModal.module.css';
import { kgz, rus, blr, kaz, mng, arm, chn, uzb } from "../../../assets/flags"
import { IBanModal, ICodeBlock, ISuperAdmin } from "../../../types/types";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { codeBlock, getCodeBlock, unblockCode } from "../../../store/adminAction";
import { ColumnsType } from "antd/lib/table";
import { service_actions } from "../../../utils/const";

export const BanCountry: React.FC<IBanModal> = ({ closeModal }: IBanModal) => {
  const superAdmin: ISuperAdmin = JSON.parse(localStorage.getItem("superAdmin") || "{}");
  const [data, setData] = useState<ICodeBlock[]>([]);
  const [code, setCode] = useState<string>('');
  const dispatch = useAppDispatch();

  const selectOptions = (
    <>
      <Select.Option value='8'><img src={rus} alt="" width={20} /> +7</Select.Option>
      <Select.Option value='8'><img src={kaz} alt="" width={20} /> +7</Select.Option>
      <Select.Option value='375'><img src={blr} alt="" width={20} /> +375</Select.Option>
      <Select.Option value='996'><img src={kgz} alt="" width={20} /> +996</Select.Option>
      <Select.Option value='998'><img src={uzb} alt="" width={20} /> +998</Select.Option>
      <Select.Option value='374'><img src={arm} alt="" width={20} /> +374</Select.Option>
      <Select.Option value='976'><img src={mng} alt="" width={20} /> +976</Select.Option>
      <Select.Option value='86'><img src={chn} alt="" width={20} /> +86</Select.Option>
    </>
  );

  useEffect(() => {
    getCodeBlockList();
  }, [])

  const getCodeBlockList = () => {
    dispatch(getCodeBlock(superAdmin)).then((response: any) => {
      setData(response.payload[0].map((item: string) => {
        return {
          code: item
        }
      }));
    })
  }

  const confirmUnbanCode = (code: string) => {
    dispatch(unblockCode({ code: code, ...superAdmin }));
    getCodeBlockList();
  }

  const columns: ColumnsType<ICodeBlock> = [
    {
      title: 'Код страны',
      dataIndex: 'code',
    },
    {
      render: (_: any, record: ICodeBlock) => {
        return (
          <Popconfirm
            title="Вы уверены?"
            okText="Да"
            cancelText="Нет"
            onConfirm={() => confirmUnbanCode(record.code)}
          >
            <Button
              type="primary"
              className={styles.unban_button}
            >
              {service_actions.unban}
            </Button>
          </Popconfirm>
        )
      }
    }
  ]

  const addToBlockList = () => {
    dispatch(codeBlock({ code, ...superAdmin }));
    closeModal();
  }

  return (
    <div className={styles.country_ban_container}>
      <div className={styles.country_ban}>
        <Select
          size='large'
          defaultValue='8'
          className={styles.country_ban_input}
          onChange={(val) => setCode(val)}
        >
          {selectOptions}
        </Select>
        <Button type="primary" size="large" className={styles.submit_button_country} onClick={() => addToBlockList()}>{service_actions.ban}</Button>
      </div>
      <Table className={styles.country_ban_container} columns={columns} dataSource={data} size="middle" pagination={false} />
    </div>
  );
};
