import Table, { ColumnsType } from "antd/lib/table"
import { IPhoneAuth, ISuperAdmin } from "../../types/types";
import styles from './PhoneAuth.module.css';
import { useCallback, useEffect, useState } from "react";
import { Button, Input, Modal, Popconfirm } from "antd";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getBlock, unblock, updateNorm } from "../../store/adminAction";
import { bl_payload, service_actions } from "../../utils/const";
import { BanPanel } from "../Modal/BanModal/BanPanel";
import { EditOutlined } from "@ant-design/icons";
import { useTypedSelector } from "../../hooks/useTypedSelector";

export const PhoneAuth: React.FC = () => {
  const { _norm } = useTypedSelector(
    (state) => state.adminReducer
  );
  const superAdmin: ISuperAdmin = JSON.parse(localStorage.getItem("superAdmin") || "{}");
  const [data, setData] = useState<IPhoneAuth[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [pageCount, setPageCount] = useState<number>(1);
  const [count, setCount] = useState<number>(1);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [norm, setNorm] = useState<number>(_norm);

  const dispatch = useAppDispatch();

  const confirmUpdate = () => {
    dispatch(updateNorm({ number: norm, ...superAdmin }));
    setIsEdit(!isEdit);
  }

  const getBlockList = useCallback(() => {
    dispatch(getBlock({ ...bl_payload, ...superAdmin })).then((response: any) => {
      setData(response.payload[0].blacklists);
      setCount(response.payload[0].count_in_blacklist);
    })
  }, [dispatch, superAdmin])

  const arrowLeft = () => {
    if (pageCount <= 1) {
      return;
    }
    setPageCount(pageCount - 1)
    const data = {
      offset: 0,
      page: pageCount - 1,
      limit: 10,
      ...superAdmin
    };

    dispatch(getBlock(data));
  }

  const arrowRight = () => {
    if (pageCount > Math.ceil(count / 10) - 1) {
      return;
    }
    setPageCount(pageCount + 1)
    const data = {
      offset: 0,
      page: pageCount + 1,
      limit: 10,
      ...superAdmin
    };

    dispatch(getBlock(data));
  }

  const confirmUnban = (phone: string) => {
    dispatch(unblock({ phone: phone }))
    getBlockList();
  }

  useEffect(() => {
    if (!isModalOpen) {
      getBlockList();
    }
  }, [getBlockList, isModalOpen])

  const columns: ColumnsType<IPhoneAuth> = [
    {
      title: 'Номер',
      dataIndex: 'phone',
    },
    {
      title: 'Причина блокировки',
      dataIndex: 'msg',
    },
    {
      title: 'Количество авторизаций за 1 час',
      dataIndex: 'number',
      render: (_: any, record: IPhoneAuth) => {
        return (
          <div className={record.number > _norm ? styles.over_auth : ''}>
            {record.number}
          </div>
        )
      }
    },
    {
      title: 'Срок блокировки (до)',
      dataIndex: 'blocked_time',
      render: (_: any, record: IPhoneAuth) => {
        return (
          <>
            {new Date(record.blocked_time).toUTCString()}
          </>
        )
      }
    },
    {
      render: (_: any, record: IPhoneAuth) => {
        return (
          <Popconfirm
            title="Вы уверены?"
            okText="Да"
            cancelText="Нет"
            onConfirm={() => confirmUnban(record.phone)}
          >
            <Button
              type="primary"
              className={styles.unban_button}
            >
              {service_actions.unban}
            </Button>
          </Popconfirm>
        )
      }
    }
  ]

  return (
    <>
      <h1 className={styles.title}>Работа с СМС</h1>
      <div className={styles.phone_auth_container}>
        <Button
          type="dashed"
          onClick={() => setIsModalOpen(true)}
          className={styles.add_button}
        >
          Добавить в бан
        </Button>
        <div className={styles.edit_delete_column}>
          <h3>Норма авторизаций за 1 час: </h3>
          {
            isEdit ?
              <>
                <Input
                  className={styles.norm_input}
                  value={norm}
                  onChange={(e) => setNorm(Number(e.target.value))}
                />
                <Button
                  size="middle"
                  type="dashed"
                  onClick={() => setIsEdit(false)}
                  className={styles.edit_button}>
                  {service_actions.cancel}
                </Button>
                <Button
                  size="middle"
                  type="primary"
                  onClick={() => confirmUpdate()}
                  className={styles.edit_button}>
                  {service_actions.done}
                </Button>
              </> :
              <>
                <h3>{_norm}</h3>
                <Button onClick={() => setIsEdit(!isEdit)} className={styles.edit_button}><EditOutlined /></Button>
              </>
          }
        </div>
        <Table columns={columns} dataSource={data} size="middle" pagination={false} />
        <div className={styles.arrow}>
          <p>
            <i className={styles.arrow_left} onClick={arrowLeft} />
          </p>
          <div className={styles.paginationCounter}>
            {pageCount}
          </div>
          <p>
            <i className={styles.arrow_right} onClick={arrowRight} />
          </p>
        </div>
        <Modal
          width={700}
          title="Блокировка пользователя"
          visible={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          footer={null}>
          <BanPanel closeModal={() => setIsModalOpen(false)} />
        </Modal>
      </div>
    </>
  )
}
