import { RadioChangeEvent } from "antd";

export interface IInitState {
   users: IUsers[];
   oneUser: IOneUser;
   users_length: number;
   isLoading: boolean;
   errors: string;
   count: number;
   _token: null | string;
   result_valid_inn: boolean;
   errors_inn: string;
   address: IAddress[];
   inn: IInn[];
   logs: ILogs[];
   codeWords: string[];
   _norm: number;
   oauthList: IAddOauthAppResponse[],
   shouldRefetchOauthApps: boolean,
   updatedOauthValue?: IAddOauthAppResponse,
   createdUsers: ISuperAdmin[]
}

export interface ILogs {
   data: string,
   event_date: string,
   event_date_time: string,
   message: string,
   service: string,
   superuser: number,
   support: number,
   type: string
}

export interface ParamsProps {
   title: string;
   getLogsFilterCallback: (filter: string, title: string) => void
}

export interface IInn {
   data: {
      inn: string;
      ogrn: string;
   };
   value: string;
}

export interface IAddress {
   data: {
      fias_id: string;
   };
   value: string;
}

export interface IOneUser {
   avatar: string;
   email: string;
   id: string;
   login: string;
   phone: string;
   user_info: IUserInfo;
   sex: string,
}

export interface IDataInJsonFormat {
   token: string | null,
   email: string,
   login: string,
   place: string,
   industry: string,
   position: string,
   inn: string,
   snils: string,
   idDoc: string,
   idDocName: string,
   name: string,
   sur_name: string,
   patronymic: string,
   role: string,
   sex: string
}

export interface IUsers {
   id: string | null;
   avatar: string | null;
   user_info: IUserInfo;
   roles: IRoles[];
   phone: string | null;
   email: string | null;
   status: string;
   created_at: string;
   deleted_at: string;
}

export interface IGetAllUsers {
   superAdmin: ISuperAdmin;
   pagination: IPagination;
   query: string;
}

export interface IGetLogs {
   superAdmin: ISuperAdmin;
   pagination: IPagination;
   query: string;
   query_service?: string;
   query_date?: string;
   query_type?: string;
}

export interface IFilters {
   service?: string;
   date?: string;
   type?: string;
}

export interface IDataForAdmin {
   users: IUsers[];
   count: number;
   oneUser: IOneUser;
   isLoading: boolean;
}

export interface IInputProfile {
   name?: string;
   value?: string;
   onChange?: any;
   type?: string;
   placeholder?: string;
   disabled?: boolean;
   onFocus?: any;
}

export interface IUserEditModule {
   setIsEditModule: (is: boolean) => void;
   superAdmin: ISuperAdmin;
   id: string | null;
   modal: boolean;
}

export interface ICheckboxStyle {
   children?: string;
   checked?: boolean;
   onChange?: any;
   isRadio?: undefined;
}

export interface IIsModalImage {
   modal: boolean;
   setModal: (is: boolean) => void;
}

export interface IUserInfo {
   birthday: IOneUserBirthday;
   docs: IOneUserDocs;
   geo: IOneUserGeo;
   passport: IOneUserPassport;
   work: IOneUserWork;
}

export interface IOneUserBirthday {
   day: number;
   month: number;
   year: number;
}

interface IDocImages {
   diploma: IDocImage | null,
   passport: IDocImage | null,
   qualification: IDocImage | null,
}

interface IDocImage {
   description: string,
   image: string,
   name: string,
   type: string
}

export interface IOneUserDocs {
   id_doc: string;
   id_doc_name: string;
   images?: IDocImages[];
   snils: string;
   snils_dop: string,
}

export interface IOneUserGeo {
   city: string;
   country: string;
   fias_addr: string;
   fias_id: string;
}

export interface IOneUserPassport {
   name: string;
   patronymic: string;
   sur_name: string;
}

export interface IOneUserWork {
   industry: string;
   inn: string;
   ogrn: string;
   place: string;
   position: string;
}

export interface IRoles {
   service: string;
   role: string;
}

export interface ISuperAdmin {
   login: string;
   password: string;
}

export interface IPagination {
   page: number;
   limit: number;
}

export interface IAvatar {
   avatar: string
   _id: string
}

export interface IGetUserData {
   token: string | null,
   query: string
}

export interface IUploadImg {
   token: string | null | undefined,
   file: any,
}

export interface IAddRole {
   id: string | null,
   role: string,
   service: string[],
   superAdmin: ISuperAdmin
}

export interface IDeactivateUser {
   superAdmin: ISuperAdmin,
   user_id: string,
   pagination: IPagination,
   query: string
}

export interface IGetUserToken {
   superAdmin: ISuperAdmin,
   user_id: string | null,
   service: string | undefined,
}

export interface ISetUsers {
   data: string,
   setIsEditModule: (is: boolean) => void
}

export interface IRadioFilterProps {
   values: string[],
   onChange: (radioValue: RadioChangeEvent) => void,
}
export interface IUserData {
   login: string,
   password: string,
}

export interface IWordsResponse {
   userData: IUserData,
   list: string[],
}

export interface SubItem {
   title: string,
   path: string
}

export interface IElements {
   order: number,
   id?: string,
   translateKey: string,
   renderConditionString: string,
   name: string,
   title: string,
   sets: string[],
   icon: string,
   route: string,
   menu: SubItem[]
}

export interface IHeaderProps {
   item: IElements,
   method: headerMethod,
   close: () => void
}

export interface IPhoneAuth {
   phone: string;
   number: number;
   msg: string;
   blocked_time: string;
}

export enum headerMethod {
   ADD = 'add',
   UPDATE = 'update'
}

export interface IBanModal {
   closeModal: () => void;
}

export interface IBlock extends ISuperAdmin {
   phone: string,
   msg: string,
   number?: number | null;
}

export interface IUpdateNorm extends ISuperAdmin {
   number: number
}

export interface IBlockList extends ISuperAdmin {
   offset: number,
   page: number,
   limit: number
}

export interface ICodeBlock extends ISuperAdmin {
   code: string;
}

export interface IAddressSelectValue {
   key: string;
   value: string;
   children: string;
   fias_id: string;
}

export interface IOnFinishValues {
   domain: string;
   name: string;
   requested_fields?: string[];
}

export interface IAddOauthApp {
   superAdmin: ISuperAdmin;
   values: IOnFinishValues;
}

export interface IAddOauthAppData extends ISuperAdmin {
   name: string;
   domain: string;
   requested_fields?: string[];
}

export interface IAddOauthAppResponse {
   domain: string;
   id: string;
   name: string;
   requested_fields?: string[];
   secret?: string;
}

export interface IDeleteOauthApp {
   superAdmin: ISuperAdmin;
   id: string;
}

export interface IDeleteOauthAppData extends ISuperAdmin {
   id: string;
}

export interface IUpdateOauthApp {
   superAdmin: ISuperAdmin;
   values: IAddOauthAppResponse;
}

export interface IUpdateOauthAppData extends IAddOauthAppData {
   id: string;
}

export interface ICreateFakeUsers extends ISuperAdmin {
   number_users: number
}
